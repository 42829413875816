import React, { useContext, useEffect, useMemo, useState } from 'react'
import { MainStylesVars, StyleSheet } from '../stylesheet'
import { useTranslation } from 'react-i18next'
import { EventsPresentationLists } from './list-by-places'
import { EventsTimelineVue } from './events-timeline'
import type { DateDay, GenreId, GuestTypeId, ProgramId, SceneId } from '../../../grab/types'
import type { StaticMainConfig } from '../chapito-static'
import { MainConfig, useLang } from '../chapito-static'
import { Button, ConfigProvider, Dropdown, Input, Select } from 'antd'
import { Icon, IconBtn } from '../components/components-lib'
import { Title } from '../components/title'
import { LangSwitchDropdown } from '../components/lang-switch-dropdown'
import { GuestsPresentationList } from './guests'
import {
  type EventsSortOrder,
  type GlobalQuery,
  type GuestSortOrder,
  type HomePageView,
  Navigation,
  getURLParamsForGlobalQuery,
  loadGlobalQuery,
  globalQueryUrlParamNames,
} from '../navigation'
import type { StaticEvent, StaticGroup, StaticData, StaticPage } from '../types'
import { faAngleDown, faAngleLeft, faAngleRight, faFilter, faMagnifyingGlass, faSliders } from '@fortawesome/free-solid-svg-icons'
import { environment } from '../environment'
import css from './home.css'
import { searchEventsByQuery, searchGuestsByQuery } from '../services/search-service'
import { eventsSortingFunctions, guestsSortingFunctions } from '../services/sorting-service'
import { exhaustiveCheck, isNotNullish } from '../../../services/typescript'
import { formatDayShort, getDateDay } from '../../../services/date'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { GuestsCompactList } from './guests-compact'

type Filter = {
  genreIds: GenreId[]
  sceneIds: SceneId[]
  programIds: ProgramId[]
}

export const HomePage: React.FC<{ data: StaticData; defaultView: number }> = ({ data, defaultView }) => {
  const { t } = useTranslation()
  const config = useContext(MainConfig)
  const { events, groups, pages } = data

  const { search: searchParams, setUrl } = useContext(Navigation)
  const view = +(searchParams.get(globalQueryUrlParamNames['view']) ?? defaultView)
  const page = pages.at(view)
  const availableDates: DateDay[] = useMemo(
    () =>
      [...new Set(events.filter((e) => page?.programs?.includes(e.programId)).map((ev) => getDateDay(ev.showStartDate, config)))].sort(
        (d1, d2) => new Date(d1).getTime() - new Date(d2).getTime()
      ),
    [events, page?.programs, config]
  )

  const [query, setQuery] = useState<GlobalQuery>(loadGlobalQuery(config, data, searchParams, defaultView, availableDates))
  useEffect(() => {
    if (environment.isBackoffice) {
      setQuery((q) => ({ ...q, view: defaultView }))
    }
  }, [defaultView])
  useEffect(() => setUrl(getURLParamsForGlobalQuery(config, query, defaultView), 'replace'))

  const [filtersVisible, setFiltersVisible] = useState<boolean>(false)

  const visibleDayCount: number = 5

  const [firstDayOffset, setFirstDayOffset] = useState<number>(Math.max(0, Math.min(availableDates.length - visibleDayCount, 0)))
  const visibleDates: DateDay[] = useMemo(
    () => availableDates.slice(firstDayOffset, Math.min(availableDates.length, firstDayOffset + visibleDayCount)),
    [availableDates, firstDayOffset]
  )
  const canAdvanceVisibleDays = firstDayOffset < availableDates.length - visibleDayCount
  const canStepBackVisibleDays = firstDayOffset > 0
  const advanceVisibleDays: (() => void) | undefined = !canAdvanceVisibleDays
    ? undefined
    : () => setFirstDayOffset(Math.max(0, Math.min(availableDates.length - visibleDayCount, firstDayOffset + visibleDayCount)))
  const stepBackVisibleDays: (() => void) | undefined = !canStepBackVisibleDays
    ? undefined
    : () => setFirstDayOffset(Math.max(0, firstDayOffset - visibleDayCount))

  const areAnyFilterSet: boolean = !!query.filterPlaces.length || !!query.filterGenres.length
  const resetFilters = (): void => setQuery({ ...query, filterPlaces: [], filterGenres: [], filterPrograms: [], searchQuery: '' })
  const toggleFilterByScene = (sceneId: SceneId): void => {
    const newPlaces = query.filterPlaces.includes(sceneId) ? query.filterPlaces.filter((s) => s !== sceneId) : [...query.filterPlaces, sceneId]
    setQuery({ ...query, filterPlaces: newPlaces })
  }
  const toggleFilterByGenre = (genreId: GenreId): void => {
    const newGenres = query.filterGenres.includes(genreId) ? query.filterGenres.filter((g) => g !== genreId) : [...query.filterGenres, genreId]
    setQuery({ ...query, filterGenres: newGenres })
  }
  const toggleFilterByProgram = (programId: ProgramId): void => {
    const newPrograms = query.filterPrograms.includes(programId)
      ? query.filterPrograms.filter((p) => p !== programId)
      : [...query.filterPrograms, programId]
    setQuery({ ...query, filterPrograms: newPrograms })
  }
  const setFilterByText = (searchQuery: string): void => setQuery({ ...query, searchQuery })
  const setSelectedView = (view: number): void => setQuery({ ...query, view })
  const setEventsSortOrder = (sortOrder: EventsSortOrder): void => setQuery({ ...query, eventsSortOrder: sortOrder })
  const setGuestsSortOrder = (sortOrder: GuestSortOrder): void => setQuery({ ...query, guestsSortOrder: sortOrder })
  const setSelectedDay = (viewDate: DateDay): void => setQuery({ ...query, viewDate })

  if (!page) return null

  // get filters only on events/guests that can be shown
  const filters = getFilterIdsForView(page, events, groups)
  const filteredEvents: StaticEvent[] =
    page.view === 'EVENTS' || page.view === 'TIMELINE' ? filterEventsByQuery(events, query, page.programs ?? [], config) : []
  const filteredGuests: StaticGroup[] =
    page.view === 'GUESTS' || page.view === 'GUESTS_COMPACT' ? filterGuestsByQuery(groups, query, page.guestTypes) : []

  return (
    <>
      <div className={css.header}>
        <ViewSwitchDropDown selectedView={query.view} pages={pages} setSelectedView={setSelectedView} />
        <Title level={1} style={{ textAlign: 'center' }} className={css.title}>
          {page.blockName}
        </Title>
        <LangSwitchDropdown className={css.langSwitchDropdown} style={{ width: 'min-content', justifySelf: 'end' }} />
        <SearchInput query={query} setFilterByText={setFilterByText} />
        {(page.view === 'EVENTS' || page.view === 'TIMELINE') && visibleDates.length > 0 && (
          <DaySelector
            currentDate={query.viewDate}
            advanceVisibleDays={advanceVisibleDays}
            setSelectedDay={setSelectedDay}
            stepBackVisibleDays={stepBackVisibleDays}
            visibleDates={visibleDates}
          />
        )}
        <div className={css.buttonsBox}>
          <SortOrderDropdown
            query={query}
            sortedItems={page.view === 'TIMELINE' ? 'none' : page.view === 'EVENTS' ? 'events' : 'guests'}
            setEventsSortOrder={setEventsSortOrder}
            setGuestsSortOrder={setGuestsSortOrder}
          />
          <FiltersToggleButton areAnyFiltersSet={areAnyFilterSet} filtersVisible={filtersVisible} setFiltersVisible={setFiltersVisible} />
        </div>
      </div>

      {/* Filters block when unfold */}
      {filtersVisible && (
        <div style={styles.filtersHeader}>
          <div style={styles.filtersContainer}>
            <FiltersPanel
              currentViewItems={page.view}
              data={data}
              filters={filters}
              query={query}
              resetFilters={resetFilters}
              toggleFilterByGenre={toggleFilterByGenre}
              toggleFilterByProgram={toggleFilterByProgram}
              toggleFilterByScene={toggleFilterByScene}
            />
          </div>
        </div>
      )}

      {/* Actual page */}
      <div style={styles.pageContent}>
        {(page.view === 'EVENTS' || page.view === 'TIMELINE') && filteredEvents.length === 0 ? (
          <div style={styles.noEventsContainer}>
            <Title level={2} style={{ marginBottom: 10 }}>
              {t('HOME.NO_EVENTS_FOR_FILTERS')}
            </Title>
            <IconBtn onClick={resetFilters}>{t('HOME.RESET_FILTERS')}</IconBtn>
          </div>
        ) : page.view === 'GUESTS' && filteredGuests.length === 0 ? (
          <div style={styles.noEventsContainer}>
            <Title level={2} style={{ marginBottom: 10 }}>
              {t('HOME.NO_GUESTS_FOR_FILTER')}
            </Title>
            <IconBtn onClick={resetFilters}>{t('HOME.RESET_FILTERS')}</IconBtn>
          </div>
        ) : page.view === 'EVENTS' ? (
          <EventsPresentationLists
            events={filteredEvents}
            cardsPerRow={page.cardsPerRow ?? 4}
            scenesHeaders={
              query.filterPlaces.length === 1
                ? 'large'
                : query.eventsSortOrder !== 'place'
                ? 'none'
                : query.filterPlaces.length > 0
                ? 'large'
                : 'normal'
            }
          />
        ) : page.view === 'TIMELINE' ? (
          <EventsTimelineVue events={filteredEvents} date={query.viewDate} timezone={config.timezone} />
        ) : page.view === 'GUESTS' ? (
          <GuestsPresentationList guests={filteredGuests} cardsPerRow={page.cardsPerRow ?? 4} />
        ) : page.view === 'GUESTS_COMPACT' ? (
          <GuestsCompactList guests={filteredGuests} cardsPerRow={page.cardsPerRow ?? 1} />
        ) : (
          exhaustiveCheck(page.view)
        )}
      </div>
    </>
  )
}

function filterGuestsByQuery(groups: StaticGroup[], query: GlobalQuery, guestTypes: GuestTypeId[]): StaticGroup[] {
  if (guestTypes.length) groups = groups.filter((g) => guestTypes.includes(g.typeId))
  if (query.filterGenres.length) groups = groups.filter((g) => g.genres?.some((genre) => query.filterGenres.includes(genre._id)))
  if (query.searchQuery.length) groups = searchGuestsByQuery(query.searchQuery, groups)
  groups.sort(guestsSortingFunctions[query.guestsSortOrder])
  return groups
}

function getFilterIdsForView(page: StaticPage, events: StaticEvent[], groups: StaticGroup[]): Filter {
  if (page.view === 'EVENTS' || page.view === 'TIMELINE') {
    const visibleEvents = page.programs?.length ? (events = events.filter((p) => page.programs?.includes(p.programId))) : events
    return {
      genreIds: [...new Set(visibleEvents.flatMap((event) => event.genres.map((genre) => genre._id)))],
      sceneIds: [...new Set(visibleEvents.map((event) => event.scene?._id).filter(isNotNullish))],
      programIds: [...new Set(visibleEvents.map((event) => event.programId))],
    }
  }
  const visibleGroups = page.guestTypes.length ? (groups = groups.filter((g) => page.guestTypes.includes(g.typeId))) : groups
  return {
    genreIds: [...new Set(visibleGroups.flatMap((group) => group.genres.map((genre) => genre._id)))],
    sceneIds: [],
    programIds: [],
  }
}

function filterEventsByQuery(events: StaticEvent[], query: GlobalQuery, programIds: ProgramId[], staticCfg: StaticMainConfig): StaticEvent[] {
  if (programIds.length) events = events.filter((e) => programIds.includes(e.programId))
  events = events.filter((ev) => getDateDay(ev.showStartDate, { timezone: staticCfg.timezone }) === query.viewDate)
  if (query.filterPlaces.length) events = events.filter((ev) => ev.scene && query.filterPlaces.includes(ev.scene?._id))
  if (query.filterGenres.length) events = events.filter((ev) => ev.genres.some((genre) => query.filterGenres.includes(genre._id)))
  if (query.filterPrograms.length) events = events.filter((ev) => query.filterPrograms.includes(ev.programId))
  if (query.searchQuery.length) events = searchEventsByQuery(query.searchQuery, events)
  events.sort((a, b) => eventsSortingFunctions[query.eventsSortOrder](a, b, staticCfg))
  return events
}

// use to wrap Antd components that are not in blocks (block background style vs widget background style)
export const WidgetBackgroundStyle: React.FC<React.PropsWithChildren> = ({ children }) => {
  const config = useContext(MainConfig)

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgBase: config.colorAction,
          colorBorder: 'transparent',
          controlItemBgActive: 'rgb(1,1,1,.2)',
          colorTextBase: config.colorBackground,
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}

const ViewSwitchDropDown: React.FC<{
  selectedView: number
  pages: StaticPage[]
  setSelectedView: (view: number) => void
}> = ({ selectedView, pages, setSelectedView }) => {
  const config = useContext(MainConfig)

  const icon = (icon: IconProp): React.ReactElement => <Icon style={{ marginRight: 5 }} color={config.colorBackground} icon={icon} />

  type ViewItem = { view: HomePageView; index: number; name: string; icon: React.ReactElement }
  const viewItems: ViewItem[] = pages.filter((p) => p.visible).map((v, i) => ({ view: v.view, index: i, name: v.blockName, icon: icon(v.icon) }))

  return viewItems.length <= 1 ? (
    <></>
  ) : (
    <WidgetBackgroundStyle>
      <Dropdown
        className={css.viewSwitchDropDown}
        trigger={['click']}
        menu={{
          items: viewItems.map(({ name, icon, index }) => ({
            label: name,
            icon,
            key: index,
            onClick: () => setSelectedView(index),
          })),
        }}
      >
        <Button type="primary" style={{ ...styles.primaryButton, display: 'flex', alignItems: 'center', width: 'min-content' }}>
          {viewItems.at(selectedView)?.icon}
          <Icon
            color={MainStylesVars.colorBackground}
            style={{ transform: 'translateY(1px)', pointerEvents: 'none', userSelect: 'none' }}
            icon={faAngleDown}
          />
        </Button>
      </Dropdown>
    </WidgetBackgroundStyle>
  )
}

const SearchInput: React.FC<{ query: GlobalQuery; setFilterByText: (text: string) => void }> = ({ query, setFilterByText }) => {
  const { t } = useTranslation()
  const config = useContext(MainConfig)

  return (
    <ConfigProvider theme={{ token: { colorTextBase: config.colorAction } }}>
      <Input
        className={css.searchInput}
        bordered={false}
        style={styles.filterInputText}
        classNames={{ input: css.filterInputTextInput }}
        placeholder={t('GENERAL.SEARCH')}
        onChange={(ev) => setFilterByText(ev.target.value)}
        value={query.searchQuery}
        prefix={<Icon icon={faMagnifyingGlass} color={MainStylesVars.colorAction} />}
      />
    </ConfigProvider>
  )
}

const FiltersToggleButton: React.FC<{ areAnyFiltersSet: boolean; filtersVisible: boolean; setFiltersVisible: (visible: boolean) => void }> = ({
  areAnyFiltersSet,
  setFiltersVisible,
  filtersVisible,
}) => {
  const { t } = useTranslation()

  return (
    <IconBtn
      className={css.filtersToggleButton}
      icon={faSliders}
      badge={areAnyFiltersSet}
      onClick={() => setFiltersVisible(!filtersVisible)}
      style={{ ...styles.primaryButton, ...styles.filtersToggleButton, ...(filtersVisible ? styles.filterToggledToggleButton : {}) }}
    >
      {t('GENERAL.FILTER')}
      <Icon
        icon={faAngleDown}
        style={{
          transform: `rotate(${filtersVisible ? -180 : 0}deg) translateY(1px)`,
          ...styles.filterArrowIcon,
        }}
        color={MainStylesVars.colorBackground}
      />
    </IconBtn>
  )
}

const DaySelector: React.FC<{
  visibleDates: DateDay[]
  stepBackVisibleDays: (() => void) | undefined
  advanceVisibleDays: (() => void) | undefined
  setSelectedDay: (day: DateDay) => void
  currentDate: DateDay | null
}> = ({ visibleDates, stepBackVisibleDays, advanceVisibleDays, setSelectedDay, currentDate }) => {
  const config = useContext(MainConfig)

  useEffect(() => {
    if (currentDate == null && visibleDates.length) {
      setSelectedDay(visibleDates[0])
    }
  }, [currentDate, visibleDates, setSelectedDay])

  return (
    <div style={styles.daysPickerWrapper} className={css.daySelector}>
      <div style={styles.daysPickerContainer}>
        <span style={{ cursor: stepBackVisibleDays ? 'pointer' : undefined }} onClick={stepBackVisibleDays}>
          <Icon style={{ height: 24 }} icon={faAngleLeft} color={stepBackVisibleDays ? config.colorAction : config.colorBackground} />
        </span>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
          {visibleDates.map((d) => (
            <h3
              key={d}
              style={{ ...styles.dayBtn, color: d === currentDate ? MainStylesVars.colorAction : MainStylesVars.colorTitle }}
              className={css.daySelectorBtn}
              onClick={() => setSelectedDay(d)}
            >
              <ShortDateLines date={d} timezone={config.timezone} />
            </h3>
          ))}
        </div>
        <span style={{ cursor: advanceVisibleDays ? 'pointer' : undefined }} onClick={advanceVisibleDays}>
          <Icon style={{ height: 24 }} icon={faAngleRight} color={advanceVisibleDays ? config.colorAction : config.colorBackground} />
        </span>
      </div>
    </div>
  )
}

const ShortDateLines: React.FC<{ date: DateDay; timezone: string }> = ({ date, timezone }) => {
  const lang = useLang()
  const [l1, ...l2] = formatDayShort(date, { lang, timezone }).toUpperCase().split(' ')
  return (
    <>
      {[l1, l2.join(' ')].map((l, i) => (
        <div key={i} style={{ whiteSpace: 'nowrap', fontSize: i === 0 ? undefined : '1rem' }}>
          {l}
        </div>
      ))}
    </>
  )
}

const SortOrderDropdown: React.FC<{
  query: GlobalQuery
  sortedItems: 'events' | 'guests' | 'none'
  setEventsSortOrder: (sortOrder: EventsSortOrder) => void
  setGuestsSortOrder: (sortOrder: GuestSortOrder) => void
}> = ({ query, sortedItems, setEventsSortOrder, setGuestsSortOrder }) => {
  const { t } = useTranslation()

  return (
    <WidgetBackgroundStyle>
      {sortedItems === 'events' ? (
        <Select<EventsSortOrder>
          suffixIcon={<Icon icon={faFilter} color={MainStylesVars.colorBackground} style={{ pointerEvents: 'none', userSelect: 'none' }} />}
          value={query.eventsSortOrder}
          onChange={setEventsSortOrder}
          options={[
            { value: 'time', label: t('GENERAL.PASSAGE_TIMES') },
            { value: 'relevance', label: t('GENERAL.RELEVANCE') },
            { value: 'place', label: t('GENERAL.PLACES') },
            { value: 'alphabetical', label: t('GENERAL.ALPHABETICAL_ORDER') },
          ]}
          className={css.sortOrderDropdown}
          popupMatchSelectWidth={false}
        />
      ) : sortedItems === 'guests' ? (
        <Select<GuestSortOrder>
          suffixIcon={<Icon icon={faFilter} color={MainStylesVars.colorBackground} style={{ pointerEvents: 'none', userSelect: 'none' }} />}
          value={query.guestsSortOrder}
          onChange={setGuestsSortOrder}
          options={[
            { value: 'relevance', label: t('GENERAL.RELEVANCE') },
            { value: 'alphabetical', label: t('GENERAL.ALPHABETICAL_ORDER') },
          ]}
          className={css.sortOrderDropdown}
          popupMatchSelectWidth={false}
        />
      ) : (
        <></>
      )}
    </WidgetBackgroundStyle>
  )
}

const FiltersPanel: React.FC<{
  currentViewItems: HomePageView
  toggleFilterByProgram: (id: ProgramId) => void
  toggleFilterByScene: (id: SceneId) => void
  toggleFilterByGenre: (id: GenreId) => void
  resetFilters: () => void
  query: GlobalQuery
  data: StaticData
  filters: Filter
}> = ({ currentViewItems, toggleFilterByGenre, toggleFilterByProgram, toggleFilterByScene, resetFilters, query, data, filters }) => {
  const { t } = useTranslation()
  const { programs, scenes, genres } = {
    programs: data.programs.filter((program) => filters.programIds.includes(program._id)),
    scenes: data.scenes.filter((scene) => filters.sceneIds.includes(scene._id)),
    genres: data.genres.filter((genre) => filters.genreIds.includes(genre._id)),
  }

  return (
    <>
      {(currentViewItems === 'EVENTS' || currentViewItems === 'TIMELINE') && (
        <>
          {programs.length > 1 ? (
            <>
              <h3 style={styles.filtersListTitle}>{t('HOME.FILTERS.PROGRAMS')}</h3>
              <div style={styles.filtersSection}>
                {programs.map((program) => (
                  <Button
                    style={query.filterPrograms.includes(program._id) ? styles.filtersButtonSelected : styles.filtersButtonUnselected}
                    key={program._id}
                    shape="round"
                    size="small"
                    onClick={() => toggleFilterByProgram(program._id)}
                  >
                    {program.name}
                  </Button>
                ))}
              </div>
            </>
          ) : (
            false
          )}
          {scenes.length > 1 ? (
            <>
              <h3 style={styles.filtersListTitle}>{t('HOME.FILTERS.PLACE')}</h3>
              <div style={styles.filtersSection}>
                {scenes.map((scene) => (
                  <Button
                    key={scene._id}
                    style={query.filterPlaces.includes(scene._id) ? styles.filtersButtonSelected : styles.filtersButtonUnselected}
                    shape="round"
                    size="small"
                    onClick={() => toggleFilterByScene(scene._id)}
                  >
                    {scene.name}
                  </Button>
                ))}
              </div>
            </>
          ) : (
            false
          )}
        </>
      )}
      {genres.length > 1 ? (
        <>
          <h3 style={styles.filtersListTitle}>{t('HOME.FILTERS.SPECIALTY')}</h3>
          <div style={styles.filtersSection}>
            {genres.map((genre) => (
              <Button
                key={genre._id}
                style={query.filterGenres.includes(genre._id) ? styles.filtersButtonSelected : styles.filtersButtonUnselected}
                shape="round"
                size="small"
                onClick={() => toggleFilterByGenre(genre._id)}
              >
                {genre.name}
              </Button>
            ))}
          </div>
        </>
      ) : (
        false
      )}
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: 8 }}>
        <IconBtn onClick={resetFilters}>{t('HOME.RESET_FILTERS')}</IconBtn>
      </div>
    </>
  )
}

const styles = StyleSheet.create({
  filtersRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  filterArrowIcon: {
    transition: '.5s transform',
    paddingLeft: 8,
    pointerEvents: 'none',
    userSelect: 'none',
    position: 'absolute',
    right: 10,
  },

  filtersHeader: {
    flexShrink: 0,
    marginBottom: 5,
  },

  filtersContainer: {
    backgroundColor: MainStylesVars.colorBlockBackground,
    borderRadius: 10,
    padding: 15,
  },

  filtersListTitle: {
    marginTop: 24,
    marginBottom: 12,
  },

  filtersSection: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
    color: MainStylesVars.colorBlockAction,
  },

  filterInputText: {
    borderBottom: `2px solid ${MainStylesVars.colorAction}`,
    borderRadius: 0,
    height: 'min-content',
  },

  primaryButton: {
    backgroundColor: MainStylesVars.colorAction,
    color: MainStylesVars.colorBackground,
    boxShadow: 'none',
  },

  filtersToggleButton: {
    paddingRight: 40,
    display: 'flex',
    justifyContent: 'center',
  },

  filterToggledToggleButton: {
    backgroundColor: MainStylesVars.colorBlockBackground,
  },

  daysPickerWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },

  daysPickerContainer: {
    display: 'flex',
    minHeight: '3rem',
    gap: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'max-content',
    flexWrap: 'nowrap',
    zIndex: 1,
  },

  dayBtn: {
    textAlign: 'center',
    userSelect: 'none',
    cursor: 'pointer',
    fontFamily: MainStylesVars.fontTitle,
  },

  pageContent: {
    marginTop: 20,
  },

  noEventsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginBottom: '10%',
  },

  filtersButtonUnselected: {
    color: MainStylesVars.colorBlockAction,
    borderColor: MainStylesVars.colorBlockAction,
    backgroundColor: MainStylesVars.colorBlockBackground,
  },

  filtersButtonSelected: {
    color: MainStylesVars.colorBlockBackground,
    borderColor: MainStylesVars.colorBlockAction,
    backgroundColor: MainStylesVars.colorBlockAction,
  },
})
